<template>
  <div>
    <div>
      <b-row
        v-if="
          revenueChartData &&
          revenueChartData.series &&
          revenueChartData.xAxisData
        "
        style="gap: 20px 0"
      >
        <b-col cols="12" class="d-flex justify-content-end mt-2">
          <b-form-checkbox v-model="comparison_mode"
            >Comparison Mode</b-form-checkbox
          >
        </b-col>

        <b-col :cols="comparison_mode ? 6 : 12">
          <b-form @submit.prevent="applyFilters">
            <b-card title="Filters">
              <b-row style="gap: 20px 0">
                <b-col :cols="comparison_mode ? 6 : 3">
                  <v-select
                    placeholder="Service"
                    v-model="revenueChartFilters.service_id"
                    :options="services"
                    label="service_name"
                    :reduce="(s) => s.service_id"
                  />
                </b-col>

                <b-col :cols="comparison_mode ? 6 : 3">
                  <v-select
                    placeholder="User Type"
                    v-model="revenueChartFilters.agent_type_key"
                    :options="agent_types"
                    label="label"
                    :reduce="(t) => t.value"
                    @input="getAgentsByKey"
                  />
                </b-col>
                <b-col :cols="comparison_mode ? 6 : 3">
                  <v-select
                    :disabled="
                      !revenueChartFilters.agent_type_key ? true : false
                    "
                    placeholder="Agent"
                    v-model="revenueChartFilters.agent_user_id"
                    :options="agents"
                    label="user_name"
                    :reduce="(a) => a.user_id"
                  />
                </b-col>
                <b-col :cols="comparison_mode ? 2 : 1">
                  <b-form-input
                    type="number"
                    min="2000"
                    max="2100"
                    v-model="revenueChartFilters.year"
                  />
                </b-col>
                <b-col :cols="comparison_mode ? 4 : 2">
                  <v-select
                    placeholder="Modes"
                    v-model="revenueChartFilters.mode"
                    :options="modeOptions"
                    label="label"
                    :reduce="(m) => m.value"
                    :clearable="false"
                  />
                </b-col>

                <b-col
                  cols="12"
                  class="d-flex justify-content-end"
                  style="gap: 10px"
                >
                  <b-button
                    type="submit"
                    variant="primary"
                    style="width: 100px"
                  >
                    Apply
                  </b-button>
                  <b-button
                    @click="resetFilters"
                    variant="warning"
                    style="width: 100px"
                  >
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-form>
        </b-col>

        <b-col :cols="comparison_mode ? 6 : 12" v-if="comparison_mode">
          <b-form @submit.prevent="compare">
            <b-card title="Filters">
              <b-row style="gap: 20px 0">
                <b-col :cols="comparison_mode ? 6 : 3">
                  <v-select
                    placeholder="Service"
                    v-model="comparisonModeData.revenueChartFilters.service_id"
                    :options="services"
                    label="service_name"
                    :reduce="(s) => s.service_id"
                  />
                </b-col>

                <b-col :cols="comparison_mode ? 6 : 3">
                  <v-select
                    placeholder="Agent Type"
                    v-model="
                      comparisonModeData.revenueChartFilters.agent_type_key
                    "
                    :options="agent_types"
                    label="label"
                    :reduce="(t) => t.value"
                    @input="getAgentsByKey"
                  />
                </b-col>
                <b-col :cols="comparison_mode ? 6 : 3">
                  <v-select
                    :disabled="
                      !comparisonModeData.revenueChartFilters.agent_type_key
                        ? true
                        : false
                    "
                    placeholder="Agent"
                    v-model="
                      comparisonModeData.revenueChartFilters.agent_user_id
                    "
                    :options="agents"
                    label="user_name"
                    :reduce="(a) => a.user_id"
                  />
                </b-col>
                <b-col :cols="comparison_mode ? 2 : 1">
                  <b-form-input
                    type="number"
                    min="2000"
                    max="2100"
                    v-model="comparisonModeData.revenueChartFilters.year"
                  />
                </b-col>
                <b-col :cols="comparison_mode ? 4 : 2">
                  <v-select
                    placeholder="Modes"
                    v-model="comparisonModeData.revenueChartFilters.mode"
                    :options="modeOptions"
                    label="label"
                    :reduce="(m) => m.value"
                    :clearable="false"
                  />
                </b-col>
                <b-col
                  cols="12"
                  class="d-flex justify-content-end"
                  style="gap: 10px"
                >
                  <b-button
                    type="submit"
                    variant="primary"
                    style="width: 100px"
                  >
                    Apply
                  </b-button>
                  <b-button
                    @click="resetFilters"
                    variant="warning"
                    style="width: 100px"
                  >
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-form>
        </b-col>

        <!-- <b-col
          cols="12"
          class="d-flex justify-content-end"
          v-if="comparison_mode"
        >
          <b-card class="my-0 p-1 pl-2" style="width: fit-content" no-body>
            <form @submit.prevent="compare">
              <div class="d-flex justify-content-center align-items-center">
                <h5 class="text-nowrap m-0 p-0 mr-1">Comparison Year</h5>

                <b-form-input
                  type="number"
                  min="2000"
                  max="2100"
                  v-model="comparisonModeYear"
                  class="mr-1"
                />

                <b-button variant="primary" type="submit">Compare</b-button>
              </div>
            </form>
          </b-card>
        </b-col> -->

        <!-- Revenue Chart -->
        <b-col :cols="comparison_mode ? 6 : 12">
          <b-card class="border h-100 p-1">
            <b-card-title class="text-center">
              {{
                `Revenue By Service ${revenueChartInfo.year} (${revenueChartInfo.mode})`
              }}
            </b-card-title>
            <revenue-chart :data="revenueChartData" />
          </b-card>
        </b-col>

        <b-col :cols="comparison_mode ? 6 : 12" v-if="comparison_mode">
          <b-card class="border h-100 p-1">
            <b-card-title class="text-center">
              {{
                `Revenue By Service ${comparisonModeData.revenueChartInfo.year} (${comparisonModeData.revenueChartInfo.mode})`
              }}
            </b-card-title>
            <revenue-chart :data="comparisonModeData.revenueChartData" />
          </b-card>
        </b-col>

        <!-- Revenue Table -->
        <b-col
          :cols="comparison_mode ? 6 : 12"
          v-if="revenueTableItems.length > 0 && revenueTableFields.length > 0"
        >
          <b-card class="border h-100 mb-0 px-1 pt-1" no-body>
            <!-- <b-card-title class="text-center mb-4"> </b-card-title> -->
            <b-table
              show-empty
              sticky-header
              responsive
              :items="revenueTableItems"
              :fields="revenueTableFields"
              class="text-right"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ minWidth: '150px' }"
                />
              </template>

              <template #cell(service)="data">
                <div
                  style="min-width: fit-content"
                  class="text-left text-nowrap"
                >
                  {{ data.item.service }}
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>

        <b-col
          :cols="comparison_mode ? 6 : 12"
          v-if="
            comparisonModeData.revenueTableItems.length > 0 &&
            comparisonModeData.revenueTableFields.length > 0 &&
            comparison_mode
          "
        >
          <b-card class="border h-100 mb-0 px-1 pt-1" no-body>
            <!-- <b-card-title class="text-center mb-4"> </b-card-title> -->
            <b-table
              show-empty
              sticky-header
              responsive
              :items="comparisonModeData.revenueTableItems"
              :fields="comparisonModeData.revenueTableFields"
              class="text-right"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ minWidth: '150px' }"
                />
              </template>

              <template #cell(service)="data">
                <div
                  style="min-width: fit-content"
                  class="text-left text-nowrap"
                >
                  {{ data.item.service }}
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>

        <!-- Service Pie Chart -->
        <b-col :cols="comparison_mode ? 6 : 8">
          <b-card
            class="border h-100 mb-0 px-1 pt-2 d-flex justify-content-center"
            no-body
          >
            <service-pie-chart
              :data="serviceChartData"
              v-if="serviceChartData"
            />
          </b-card>
        </b-col>

        <b-col :cols="comparison_mode ? 6 : 8" v-if="comparison_mode">
          <b-card
            class="border h-100 mb-0 px-1 pt-2 d-flex justify-content-center"
            no-body
          >
            <service-pie-chart
              :data="comparisonModeData.serviceChartData"
              v-if="comparisonModeData.serviceChartData"
            />
          </b-card>
        </b-col>

        <!-- Service Data -->
        <b-col :cols="comparison_mode ? 6 : 4">
          <b-card class="border h-100 p-1">
            <b-card-title class="text-center mb-4">
              Revenue By Service Totals
            </b-card-title>
            <div
              class="d-flex justify-content-between align-items-center border-bottom py-2"
              v-for="rdt of revenueChartDataTotals"
              :key="'rdt_' + rdt.name"
            >
              <h6 class="mr-1" v-if="!rdt.isTotal">{{ rdt.name }}</h6>
              <h3 class="mr-1" v-if="rdt.isTotal">{{ rdt.name }}</h3>
              <p :style="rdt.isTotal && 'font-weight: bold; font-size: 1.4rem'">
                {{ getSymbolFromCurrency("INR") }} {{ addCommas(rdt.total) }}
              </p>
            </div>
          </b-card>
        </b-col>

        <b-col :cols="comparison_mode ? 6 : 4" v-if="comparison_mode">
          <b-card class="border h-100 p-1">
            <b-card-title class="text-center mb-4">
              Revenue By Service Totals
            </b-card-title>
            <div
              class="d-flex justify-content-between align-items-center border-bottom py-2"
              v-for="rdt of comparisonModeData.revenueChartDataTotals"
              :key="'rdt_' + rdt.name"
            >
              <h6 class="mr-1" v-if="!rdt.isTotal">{{ rdt.name }}</h6>
              <h3 class="mr-1" v-if="rdt.isTotal">{{ rdt.name }}</h3>
              <p :style="rdt.isTotal && 'font-weight: bold; font-size: 1.4rem'">
                {{ getSymbolFromCurrency("INR") }} {{ addCommas(rdt.total) }}
              </p>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BCardTitle,
  BFormInput,
  BForm,
  BTable,
  BFormCheckbox,
} from "bootstrap-vue";
import AccountsServices from "@/apiServices/AccountsServices";
import RevenueChart from "@/views/components/AccountsHomeComponents/RevenueChart.vue";
import ServicePieChart from "@/views/components/AccountsHomeComponents/ServicePieChart.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    RevenueChart,
    vSelect,
    flatPickr,
    BButton,
    BCardTitle,
    BFormInput,
    BForm,
    BTable,
    ServicePieChart,
    BFormCheckbox,
  },
  data() {
    return {
      // Masters
      services: [],
      agents: [],
      agent_types: [
        {
          label: "Counselor",
          value: "counselor",
        },
        {
          label: "Franchise",
          value: "franchise",
        },
      ],

      // Analytics
      revenueChartData: {},
      serviceChartData: [],
      revenueChartInfo: {
        year: moment().year(),
        mode: "Full Year",
      },
      revenueChartDataTotals: [],
      revenueTableFields: [],
      revenueTableItems: [],

      // Comparison
      comparisonModeData: {
        revenueChartData: {},
        serviceChartData: [],
        revenueChartInfo: {
          year: moment().subtract(1, "year").year(),
          mode: "Full Year",
        },
        revenueChartDataTotals: [],
        revenueTableFields: [],
        revenueTableItems: [],

        // filters
        revenueChartFilters: {
          mode: "full_year",
          service_id: null,
          year: moment().subtract(1, "year").year(),
          agent_type_key: "",
          agent_user_id: null,
        },
      },

      // Filters
      revenueChartFilters: {
        mode: "full_year",
        service_id: null,
        year: moment().year(),
        agent_type_key: "",
        agent_user_id: null,
      },

      modeOptions: [
        { label: "Full Year", value: "full_year" },
        { label: "First Quarter", value: "first_quarter" },
        { label: "Second Quarter", value: "second_quarter" },
        { label: "Third Quarter", value: "third_quarter" },
        { label: "Fourth Quarter", value: "fourth_quarter" },
      ],

      // Flags
      comparison_mode: false,
    };
  },
  methods: {
    // Apis
    async getRevenueByService() {
      try {
        const res = await AccountsServices.getRevenueByServices(
          this.revenueChartFilters
        );

        if (res.data.status) {
          this.revenueChartData = res.data.data;

          // Calculate Revenue Chart Data
          const { totals, serviceChartData } = this.getRevenueChartTotals(
            this.revenueChartData
          );

          this.revenueChartDataTotals = totals;
          this.serviceChartData = serviceChartData;

          // Calculate Revenue Table Data
          const { fields, items } = this.getRevenueTableData(
            this.revenueChartData
          );
          this.revenueTableFields = fields;
          this.revenueTableItems = items;
        }
      } catch (error) {
        console.log("error: ", error);
      }
    },

    async getComparisonRevenueByService() {
      try {
        const res = await AccountsServices.getRevenueByServices(
          this.comparisonModeData.revenueChartFilters
        );

        if (res.data.status) {
          this.comparisonModeData.revenueChartData = res.data.data;

          // Calculate Revenue Chart Data
          const { totals, serviceChartData } = this.getRevenueChartTotals(
            this.comparisonModeData.revenueChartData
          );

          this.comparisonModeData.revenueChartDataTotals = totals;
          this.comparisonModeData.serviceChartData = serviceChartData;

          // Calculate Revenue Table Data
          const { fields, items } = this.getRevenueTableData(
            this.comparisonModeData.revenueChartData
          );
          this.comparisonModeData.revenueTableFields = fields;
          this.comparisonModeData.revenueTableItems = items;
        }
      } catch (error) {
        console.log("error: ", error);
      }
    },

    async getAllServices() {
      try {
        const res = await AccountsServices.getAllServices();
        if (res.data.status) {
          this.services = res.data.data;
        }
      } catch (error) {
        console.log("error: ", error);
      }
    },

    async getAgentsByKey() {
      if (!this.revenueChartFilters.agent_type_key) return;
      this.revenueChartFilters.agent_user_id = null;

      try {
        const res = await AccountsServices.getAllAgents({
          user_type_key: this.revenueChartFilters.agent_type_key,
        });

        if (res.data.status) {
          this.agents = res.data.data;
        }
      } catch (error) {
        console.log("error: ", error);
      }
    },

    // Actions
    applyFilters() {
      this.revenueChartInfo = {
        year: this.revenueChartFilters.year,
        mode: this.modeOptions.find(
          (m) => m.value === this.revenueChartFilters.mode
        ).label,
      };

      this.getRevenueByService();
      this.compare();
    },

    resetFilters() {
      this.revenueChartFilters = {
        service_id: null,
        mode: "full_year",
        year: moment().year(),
        agent_type_key: "",
        agent_user_id: null,
      };

      this.getRevenueByService();
    },

    compare() {
      this.comparisonModeData.revenueChartInfo = {
        year: this.comparisonModeData.revenueChartFilters.year,
        mode: this.modeOptions.find(
          (m) => m.value === this.revenueChartFilters.mode
        ).label,
      };

      this.getComparisonRevenueByService();
    },

    // Helpers
    moment,
    getSymbolFromCurrency,
    addCommas(amt) {
      return amt.toLocaleString("en-IN");
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.isTotal) return "table-primary";
    },

    getRevenueChartTotals(data) {
      let totals = [];

      console.log({ data });

      for (let series of data.series) {
        let obj = {
          name: series.name,
          total: series.data.reduce((acc, d) => acc + d, 0),
        };

        totals.push(obj);
      }

      let serviceChartData = totals.map((a) => ({ ...a, value: a.total }));

      let totalObj = {
        name: "Total",
        isTotal: true,
        total: totals.reduce((acc, t) => acc + t.total, 0),
      };
      totals.push(totalObj);

      return { totals, serviceChartData };
    },

    getRevenueTableData(data) {
      // fields
      let fields = [
        {
          key: "service",
          label: "Service",
          stickyColumn: true,
        },
      ];

      data.xAxisData.forEach((d) => fields.push({ key: d, label: d }));

      fields.push({ key: "total", label: "Total", variant: "primary" });

      // Items
      let items = [];
      for (let d of data.series) {
        let obj = {};

        for (let f of fields) {
          obj[`${f.key}`] = null;
        }

        items.push(obj);
      }

      for (let i = 0; i < items.length; i++) {
        items[i]["service"] = data.series[i].name;

        let index = 0;
        let total = 0;
        for (let key in items[i]) {
          if (key === "service" || key === "total") continue;
          items[i][key] = data.series[i].data[index];

          total += data.series[i].data[index];
          index++;
        }

        items[i]["total"] = total;
      }

      // Adding Total
      let obj = {
        service: "Total",
      };

      let index = 0;
      let total = 0;
      for (let key in items[0]) {
        if (key === "service" || key === "total") continue;
        obj[key] = items.reduce((acc, item) => acc + item[key], 0);
        obj["_cellVariants"] = { ...obj["_cellVariants"], [key]: "primary" };

        total += obj[key];
        index++;
      }

      obj["total"] = total;
      obj["isTotal"] = true;
      obj["_cellVariants"] = { ...obj["_cellVariants"], total: "warning" };

      items.push(obj);

      items.map((item) => {
        for (let key in item) {
          if (["service", "isTotal", "_cellVariants"].includes(key)) continue;

          item[key] = `${this.getSymbolFromCurrency("INR")} ${this.addCommas(
            item[key]
          )}`;
        }
      });

      console.log({ items });

      // this.revenueTableFields = fields;
      // this.revenueTableItems = items;

      return { fields, items };
    },
  },
  beforeMount() {
    this.getAllServices();
    this.getRevenueByService();
    this.getComparisonRevenueByService();
  },
};
</script>