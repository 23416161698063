<template>
  <div>
    <e-charts
      class="w-100"
      ref="pie"
      autoresize
      :options="option"
      theme="theme-color"
      auto-resize
    />
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/pie";
import theme from "./theme.json";
import moment from "moment";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  computed: {
    option() {
      return {
        title: {
          text: "Referer of a Website",
          subtext: "Fake Data",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "horizontal",
          left: "center",
        },
        series: [
          {
            name: "Revenue From",
            type: "pie",
            radius: "60%",
            data: this.data,
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: "rgba(0, 0, 0, 0.5)",
            //   },
            // },
          },
        ],
      };
    },
  },
};
</script>
